.icon-check {
  align-items: center;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  width: 3.5rem;
}

.card-payment-info {
  border-radius: 15px;
  position: relative;
}

.info-buy {
  border-top: 2px dashed #ddd;
}

.card-payment-info::after, .card-payment-info::before {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background: #eff1f7;
  border-radius: 100%;
}

.card-payment-info::before {
  left: -16px;
  top: 68%;
}

.card-payment-info::after {
  right: -16px;
  top: 68%;
}

.card-payment_header {
  background: #ff6700;
}

.card-payment-info_container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 6rem;
}

.card-payment-info_container > img {
  object-fit: cover;
  height: 8.5rem;
}

@media screen and (min-width: 375px) {
  .card-payment-info .card-body {
    padding: 2rem 3rem;
  }
}
