.corban-list {
  list-style: none;
  width: 96%;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 999;
}

.item-list {
  padding: 8px 0 8px 20px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #cad1d7;
  border-top: none;
  &:hover {
    background: #ebeced;
  }
}

.last-item{
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
