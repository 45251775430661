.table-responsive {
  padding: 1rem 0 3rem 0;
}

table.contracts,
table.list-payments {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

table.contracts tr th.word-wrap {
  width: 150px;
  white-space: normal;
}

table.contracts td + td,
table.contracts th + th {
  width: auto;
}

table.list-payments .id {
  width: 3rem;
  white-space: normal;
}

table.list-payments .amount {
  width: 6rem;
  white-space: normal;
}

table.list-payments .installments {
  width: 4rem;
  white-space: normal;
}

table.list-payments .created_at {
  width: 7rem;
  white-space: normal;
}

table.list-payments .status {
  width: 1rem;
  white-space: normal;
}

table.list-payments .message {
  width: 10rem;
  white-space: normal;
}
