.table-responsive {
  padding: 1rem 0 3rem 0;
}

table.contracts,
table.list-payments {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

table.contracts tr th.word-wrap {
  width: 150px;
  white-space: normal;
}

table.contracts td + td,
table.contracts th + th {
  width: auto;
}

table.list-payments .id {
  width: 3rem;
  white-space: normal;
}

table.list-payments .amount {
  width: 6rem;
  white-space: normal;
}

table.list-payments .installments {
  width: 4rem;
  white-space: normal;
}

table.list-payments .created_at {
  width: 7rem;
  white-space: normal;
}

table.list-payments .status {
  width: 1rem;
  white-space: normal;
}

table.list-payments .message {
  width: 10rem;
  white-space: normal;
}

table tbody tr.blacklist {
  background-color: rgba(245, 54, 92, 0.5);
}

.imagePreview {
  width: 100%;
}

.dropzoneFile {
  border: 1px dashed #ccc;
  padding: 32px 16px;
}

.closeButtonImage > span {
  color: red !important;
  font-size: 32px !important;
  position: absolute;
  right: 10%;
  top: 10%;
}

.positionRelative {
  position: relative;
}

.table-responsive {
  padding: 1rem 0 20rem !important;
}

.form-control-alternative-custom {
  box-shadow: none !important;
  background-color: transparent !important;
  color: black !important;
}

.auto-complete-list {
  list-style: none;
  width: 96%;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 999;
}

.auto-complete-item-list {
  padding: 8px 0 8px 20px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #cad1d7;
  border-top: none;

  &:hover {
    background: #ebeced;
  }
}

.last-item {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
