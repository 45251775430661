#modal-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 30, 28, .8);
  box-sizing: border-box;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000;
}

.loading-icon {
  display: flex;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  animation-name: spin;
  animation-duration: 2s;
  transition-timing-function: linear;
  animation-iteration-count: infinite;
  animation: spin 2s linear infinite;
}

.loading-icon div {
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
