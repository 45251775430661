/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "argon-dashboard/custom/functions";
@import "argon-dashboard/custom/variables";
@import "argon-dashboard/custom/mixins";

// bootstrap (4.6.0) components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Argon utilities and components

@import "argon-dashboard/custom/reboot";
@import "argon-dashboard/custom/utilities";
@import "argon-dashboard/custom/components";

// Vendor (Plugins)

@import "argon-dashboard/custom/vendors";

// Docs components

@import "argon-dashboard/docs/variables";
@import "argon-dashboard/docs/nav";
@import "argon-dashboard/docs/clipboard-js";
@import "argon-dashboard/docs/component-examples";
@import "argon-dashboard/docs/prism";
@import "argon-dashboard/docs/content";
@import "argon-dashboard/docs/sidebar";
@import "argon-dashboard/docs/footer";

// React Differences
@import "react/react-differences";


.input-custom-simulator{
  height: 83px;
  font-size: 32px;
}

.container-custom-simulator {
  display: flex;
  flex-direction: column;
}

.label-custom-simulator {
  // color: #EF7608;
  font-size: 32px;
}

.label-range-input {
  color: #EF7608;
  font-size: 16px;
}

.container-results {
  background: #eeeeee;
  padding: 12px;
}

.title-result-section {
  color: #A6A6A6;
  font-size: 24;
  font-weight: bold;
}

.title-result-section-medium {
  color: #A6A6A6;
  font-size: 12px;
  font-weight: bold;
}

.value-result-section {
  color: #EF7608;
  font-size: 32px;
  font-weight: bold;
}

.installment-result-section {
  font-size: 16px;
  color: #A6A6A6;
  margin-right: 8px;
}

.result-custom-simulator {
  height: 83px;
  font-size: 32px;
  color: #EF7608;
  font-weight: bold;
  background-color: #eeeeee !important;
  border: none;
}

.result-custom-simulator-medium {
  height: 83px;
  font-size: 20px;
  color: #EF7608;
  font-weight: bold;
  background-color: #eeeeee !important;
  border: none;
}

.result-custom-simulator-medium-not-height {
  font-size: 16px;
  color: #EF7608;
  font-weight: bold;
  background-color: #eeeeee !important;
  border: none;
}


.installment-section {
  display: flex;
  align-items: center;
}

.isSelectedFilter {
  // border: 2px solid #c16161;
  background: #2dce89;
}
.isSelectedFilterText {
  color: white,
}
.bg-gradient-fgts {
  background: linear-gradient(87deg, #f53636 0, #1171ef 100%) !important; }

.bg-gradient-cartao {
  background: linear-gradient(87deg, #2dce89 0, #1171ef 100%) !important; }

.bg-info-custom{
  background: #2d8bce !important;
}
.bg-warning-custom{
  background: #ce8e2d !important;
}

.swal-button--confirm {
  background-color: #2dce89 !important;
}
