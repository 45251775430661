.corban-list {
  list-style: none;
  width: 96%;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 999;
}

.item-list {
  padding: 8px 0 8px 20px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #cad1d7;
  border-top: none;
  &:hover {
    background: #ebeced;
  }
}

.last-item{
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.collumn-container {
  display: flex;
  flex-direction: column;
}

.marks-container {
  width: 100%;
  height: 10px;
}

/*     margin-right: -31px;  margin-left: -27px;*/

.between-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.item-container {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 4px 18px;
  border-radius: 4px;
  background: #548bf4;
  margin-top: -22px;
}

.item-0 {
  margin-left: -27px;
}

.item-1 {
  margin-right: -4px;
}

.item-2 {
  margin-right: -31px;
}